<template>
  <v-container>
    <div class="carrito">
      <div class="carrito-titulo-1">Cuentas claras sin un depende</div>

      <div class="linea-fibra-1">
        {{ this.fibraA.descripcion }}
      </div>
      <div v-for="item in lineasFijoMovil" v-bind:key="item.id">
        <span class="linea-fijo-movil-1">
          {{ item.descripcion }}
        </span>
      </div>
      <hr class="solid" />
      <div class="carrito-x1-1">
        <div>TOTAL (iva incluido)</div>
        <div class="carrito-total-x1-1">{{ total | puntoPorComa }}€/mes</div>
      </div>
      <div class="carrito-condiciones">
        <div class="carrito-condiciones-li">-Instalación gratuita</div>
        <div class="carrito-condiciones-li">-Sin subidas de precio</div>
        <div class="carrito-condiciones-li">-Compra segura</div>
        <div class="carrito-condiciones-li">-¡Sin permanencia!</div>
      </div>
    </div>
  </v-container>
</template>
<script>
export default {
    data: () => ({
        messages: [],
        fibraA: [],
        lineasFijoMovil: [],
        datosPortabilidadesValidados: false,
        total: 0
    }),
    methods: {
        contratar () {
            this.$router.push('/order')
        }
    },

    computed: {
        VdatosPortabilidadesValidados () {
            return this.$store.getters.getdatosPortabilidadesValidados
        },
        Vfibra () {
            return this.$store.getters.getFibra
        },
        Vmovil () {
            return this.$store.getters.getLineasMovil
        },
        VswitchFijo () {
            return this.$store.getters.getswitchFijo
        }
    },
    watch: {
        VdatosPortabilidadesValidados (value) {
            if (value) {
                this.datosPortabilidadesValidados = true
            } else {
                this.datosPortabilidadesValidados = false
            }
        },
        Vfibra (value) {
            this.fibraA = value
            if (this.$store.getters.getswitchFijo) {
                const fijo = this.messages[1]
                this.messages = []
                this.lineasFijoMovil = []

                this.messages[0] = value
                this.total = value.precio
                this.messages.push(fijo)
                this.total = this.total + fijo.precio
                this.$store.commit('SET_TOTAL', this.total)
            } else {
                this.messages = []
                this.lineasFijoMovil = []

                this.messages[0] = value
                this.total = value.precio
            }

            if (this.$store.getters.getLineasMovil.length > 0) {
                this.$store.getters.getLineasMovil.forEach((element) => {
                    this.total = this.total + element.precio
                    this.lineasFijoMovil.push(element)
                    this.messages.push(element)
                })
            }

            this.total = this.total.toFixed(2)
            this.$store.commit('SET_TOTAL', this.total)

            this.$store.dispatch('setcarritoPedidosLineasMovil', this.messages)
        },
        Vmovil (value) {
            const fibra = this.messages[0]
            if (this.$store.getters.getswitchFijo) {
                const fijo = this.messages[1]
                this.messages = []
                this.lineasFijoMovil = []
                this.messages.push(fibra)
                this.messages.push(fijo)
                this.lineasFijoMovil.push(fijo)

                this.total = fibra.precio
                this.total = this.total + fijo.precio
            } else {
                this.messages = []
                this.lineasFijoMovil = []

                this.messages.push(fibra)
                this.total = fibra.precio
            }
            value.forEach((element) => {
                this.total = this.total + element.precio
                this.messages.push(element)
                this.lineasFijoMovil.push(element)
            })
            this.total = this.total.toFixed(2)
            this.$store.commit('SET_TOTAL', this.total)

            this.$store.dispatch('setcarritoPedidosLineasMovil', this.messages)
        },
        VswitchFijo (value) {
            if (value) {
                const fibra = this.messages[0]
                this.messages = []
                this.lineasFijoMovil = []

                this.messages.push(fibra)
                this.total = fibra.precio
                this.messages.splice(1, 1, value)
                this.lineasFijoMovil.splice(1, 1, value)

                this.total = this.messages[0].precio
                this.total = this.messages[1].precio + this.total

                if (this.$store.getters.getLineasMovil.length > 0) {
                    this.$store.getters.getLineasMovil.forEach((element) => {
                        this.total = this.total + element.precio
                        this.messages.push(element)
                        this.lineasFijoMovil.push(element)
                    })
                }
                this.total = this.total.toFixed(2)
                this.$store.commit('SET_TOTAL', this.total)
            } else {
                this.messages.splice(1, 1)
                const fibra = this.messages[0]
                this.messages = []
                this.lineasFijoMovil = []

                this.messages.push(fibra)
                this.total = this.messages[0].precio
                if (this.$store.getters.getLineasMovil.length > 0) {
                    this.$store.getters.getLineasMovil.forEach((element) => {
                        this.total = this.total + element.precio
                        this.messages.push(element)
                        this.lineasFijoMovil.push(element)
                    })
                }
                this.total = this.total.toFixed(2)
                this.$store.commit('SET_TOTAL', this.total)
            }
            this.$store.dispatch('setcarritoPedidosLineasMovil', this.messages)
        }
    },
    created () {
        if (!this.$store.getters.getfibraPreseleccionada) {
            const fibra = {
                codigo_articulo: 'FG1000',
                tipo: 'fibra',
                descripcion: 'Fibra  1000Mb',
                unidades: '1',
                apartment_id: this.$store.getters.getcodigoCoberturaFibra,
                precio: 32.9,
                importe: 32.9
            }
            this.$store.commit('SET_FIBRA', fibra)
            this.$store.dispatch('setcarritoPedidosLineasMovil', this.messages)
        } else {
            const fibra = {
                codigo_articulo: 'FG100',
                tipo: 'fibra',
                descripcion: 'Fibra  100Mb',
                unidades: '1',
                apartment_id: this.$store.getters.getcodigoCoberturaFibra,
                precio: 21.9,
                importe: 21.9
            }
            this.$store.commit('SET_FIBRA', fibra)
            this.$store.dispatch('setcarritoPedidosLineasMovil', this.messages)
        }
    }
}
</script>
